import React from 'react';
import { Container } from 'theme-ui';
import SEO from '../components/seo';
import PrimaryLayout from '../components/layout/primary/primary';
import {  AmplifySignOut } from '@aws-amplify/ui-react';
import AuthStateApp from '../components/Authenticator/AuthStateApp'
import Profile from '../components/profile/profile';

const ProfilePage: React.FC<{}> = () => (
	
	<PrimaryLayout>
		<SEO title="FARMTOTRUCKTHAILAND: Profile" />
		<Container py="40px">
		<AuthStateApp>
			< Profile />
		</AuthStateApp>	
		</Container>
	</PrimaryLayout>
	
);

export default ProfilePage;
