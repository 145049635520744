import React from "react";
import { Flex, Box, Text, Button } from "theme-ui";
import { IoIosAdd, IoIosRemove, IoIosCloseCircle } from "react-icons/io";
import Img from "gatsby-image";
import styles from "./order-item.style";

const OrderItem = (props: any) => {
	const { item } = props;

	return (
		<>
			<Flex sx={styles.wrapper}>
				<Box sx={styles.contentWrapper}>
					<Box>
						<Text sx={styles.title}>{item.node.title}</Text>
						<Text sx={styles.unitPrice}>
							Unit Price {(item.node.discountedTotalPrice.amount/item.node.quantity).toFixed(2)}
						</Text>
					</Box>
					<Box sx={styles.counterWrapper}>
						<Text sx={styles.totalPrice}>
							Quantity: {item.node.quantity}
						</Text>
						<Text sx={styles.totalPrice}>
							{item.node.discountedTotalPrice.amount }
						</Text>
					</Box>
				</Box>
			</Flex>
		</>
	);
};

export default OrderItem;
