const styles = {
	grid: {
		a: {
			color: 'primary' ,
			fontSize: '14px',
			position: 'relative',
			'@media only screen and (min-width: 1081px)': {
				'&:hover': {
					color: 'black',
					textDecoration: 'underline',
				},
				'&.active': {
					color: 'black',
					fontWeight: '600',
				},
			},
			'@media only screen and (max-width: 1080px)': {
				'&:hover, &.active': {
					color: 'black',
					fontWeight: '600',
				},
			},
		},
	},
};

export default styles;
