/** @jsx jsx */
import React from 'react';
import {  AmplifySignOut } from '@aws-amplify/ui-react';
import Amplify from 'aws-amplify';
import awsconfig from '../../aws-exports';
import { Grid, Box, jsx } from 'theme-ui';
import { animated, useSpring, useChain } from 'react-spring';
import Order from './Order';
import styles from './profile.style';

Amplify.configure(awsconfig);

const Profile = ({user}) => {
	
	//const [open, set] = React.useState(false);
	const [display, set] = React.useState({open: false, id: ""});
	const cartOverlayRef = React.useRef();
	// @ts-ignore
	const { size, ...rest } = useSpring({
		ref: cartOverlayRef,
		config: { mass: 1, tension: 800, friction: 50 },
		from: {
			size: '70%',
			left: '15%',
			opacity: 0,
			bottom: '30px',
			borderRadius: 10,
		},
		to: {
			opacity: display.open ? 1 : 0,
			size: display.open ? '100%' : '70%',
			left: display.open ? '0' : '15%',
			bottom: display.open ? '0' : '30px',
			borderRadius: display.open ? 0 : 10,
		},
	});
	useChain(
		// @ts-ignore
		display.open ? [ cartOverlayRef] : [cartOverlayRef],
		[0, display.open ? 0.1 : 0.2]
	);
	
	const [orders, setOrders] = React.useState([]);
    React.useEffect(() => {
    
    async function fetchData() {
	 var tokenJson = null;
	 //console.log("user: ", user);
 
	 if (user) {
		 const email =  user.attributes.email;  
		 const pass =  user.attributes.sub.substring(0, 13);
		  var query = `mutation {
				  customerAccessTokenCreate(
					  input: {
						email: "${email}",
						password: "${pass}"
					  }
					)
					{
						customerAccessToken {
						  accessToken
						  expiresAt
						}
						customerUserErrors {
						  code
						  field
						  message
						}
					  }            
				}
			`;

			var requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/graphql',
							'Accept': 'application/json',
							'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
				body: query
			};

			var response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-10/graphql', requestOptions );
			tokenJson = await response.json();
			//console.log(tokenJson); 
			const accessToken = tokenJson.data.customerAccessTokenCreate.customerAccessToken.accessToken;
			var createDate = new Date() - 30 * 24 * 3600 * 1000
			createDate = new Date(createDate)
			createDate = createDate.toISOString().split('T')[0];
			query = `{customer(customerAccessToken:"${accessToken}") {
					  id
					  orders(first:30, reverse: true, query:"processed_at:>${createDate}") {
						edges {
						  node {
							id
							processedAt
							financialStatus
							totalPriceV2 {
							  amount
							}
							orderNumber        
						  }
						}
					  }
					}}
			`;
			requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/graphql',
							'Accept': 'application/json',
							'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
				body: query
			};			
			response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-10/graphql', requestOptions );
			const ordersData = await response.json();
			setOrders(ordersData.data.customer.orders.edges);
			//console.log("orders: ", ordersData)
		}
		}
		fetchData();
    }, []);
	//console.log("user: ", user)
	return (
		<>
			<h2>Orders</h2>
			<Grid gap={'1px'} width={[ 64, null, 82 ]}>
			  <Box p={2} color='white' bg='primary'>Number</Box>
			  <Box p={2} color='white' bg='primary'>Date</Box>
			  <Box p={2} color='white' bg='primary'>Amount</Box>
			  <Box p={2} color='white' bg='primary'>Status</Box>
			</Grid>	
			{orders.length > 0 && 
			orders.map( (order, index) => (
				<Grid gap={'1px'} width={[ 64, null, 82 ]} key={order.node.orderNumber} mb={'1px'} sx={styles.grid}>
				  <Box p={2} bg='muted'><a href="#" onClick={() => set({open: !display.open, id: order.node.id})}>{order.node.orderNumber}</a></Box>
				  <Box p={2} bg='muted'>{order.node.processedAt.split('T')[0]}</Box>
				  <Box p={2} bg='muted'>{order.node.totalPriceV2.amount}</Box>
				  <Box p={2} bg='muted'>{order.node.financialStatus}</Box>				
				</Grid>
			))
			}
			<Box p={2}>	
				<AmplifySignOut buttonText="Sign Out"></AmplifySignOut>
			</Box>
			<animated.div
				style={{
					...rest,
					width: size,
					height: size,
					position: 'fixed',
					backgroundColor: 'white',
					pointerEvents: display.open ? 'all' : 'none',
					zIndex: 9999,
				}}
			>
				<Order onClick={() => set({open: false, id: ""})} id={display.id}>
				</Order>
			</animated.div>			
		</>
	)
}


export default Profile;