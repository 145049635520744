/** @jsx jsx */
import { useContext, useState, useEffect } from 'react';
import { Box, Flex, Heading, Text, Button, jsx, Label, Alert, Spinner, Container } from 'theme-ui';
import { IoIosClose } from 'react-icons/io';
import styles from './order.style';
import OrderItem from './order-item/order-item';


const Order: React.FunctionComponent<{ onClick?: () => void }> = ({
	onClick,
	id,
}: any) => {	 
	
	const [order, setOrder] = useState(null);	
	useEffect(() => {
    async function fetchData() {
 
	 if (id) {

		  var query = `{
						 node(id: "${id}") {
							... on Order {
								id
								totalPrice
								subtotalPrice
								totalTax
								totalShippingPriceV2 {
									amount
								}
								lineItems(first: 250) {
									edges {
										node {
											quantity
											title
											discountedTotalPrice  {
												amount 
											}
											variant {
												id
											}
										}
									}
								}
							}
						}
					}
			`;

			var requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/graphql',
							'Accept': 'application/json',
							'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
				body: query
			};

			var response = await fetch('https://farmtotruckthailand.myshopify.com/api/2020-10/graphql', requestOptions );
			var orderData = await response.json();
			//console.log(tokenJson); 
			setOrder(orderData.data.node);
			//console.log("orders: ", ordersData)
		}
		}
		fetchData();		
	},[id])	
	
	if (order) {
	return (
		<Box sx={styles.wrapper}>
			<Heading as="h2" sx={styles.title}>
				Order
				<Button title="Close" variant="text" onClick={() => { onClick()}}>
					<IoIosClose />
				</Button>
			</Heading>
			<Flex sx={styles.totalPrice}>	
				<Box>
					<Text>Subtotal:</Text>
					<Heading as="h5">{order.subtotalPrice}</Heading>									
				</Box>	
				<Box>
					<Text>Shipping:</Text>
					<Heading as="h5">{order.totalShippingPriceV2.amount}</Heading>									
				</Box>								
				<Box>
					<Text>Total Amount:</Text>
					<Heading as="h4">{order.totalPrice}</Heading>
				</Box>
			</Flex>			
			<Box sx={styles.cartItems}>
			<Box>
				{ order.lineItems.edges.map((item) => (
					<OrderItem key={`order-item-${item.node.variant.id}`} item={item} />
				))}	
			</Box>			
			</Box>
		</Box>
	) }
	else {
	 return(	<Container sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} >
		<Spinner />
		</Container>)};	

};

export default Order;
